<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        <v-card>
          <v-card-title>
            <v-spacer />
            {{$t('m.billing.title')}}
            <v-spacer />
          </v-card-title>
          <div v-if="isInvoice">
            <v-card-text>{{$t('m.billing.invoice')}}</v-card-text>
          </div>
          <div v-else>
            <v-sheet tile color="secondary lighten-1">
              <v-card-text>
                <v-row no-gutters>
                  <v-col class="title">{{$t('m.billing.plan.title')}}</v-col>
                </v-row>
              </v-card-text>
            </v-sheet>
            <v-row class="mx-2">
              <v-col cols="auto" class="body-1" v-if="planId === stripePlanFree">
                <div>{{$t('m.billing.plan.text3_1')}}</div>
              </v-col>
              <v-col cols="auto" class="body-1" v-else-if="planId === stripePlan5K">
                <div>{{$t('m.billing.plan.text14_1')}}</div>
              </v-col>
              <v-col cols="auto" class="body-1" v-else-if="planId === stripePlan30K">
                <div>{{$t('m.billing.plan.text')}}</div>
              </v-col>
              <v-col cols="auto" class="body-1" v-else>
                <div>{{$t('m.billing.plan.noPlan')}}</div>
              </v-col>
            </v-row>
            <v-sheet tile color="secondary lighten-1">
              <v-card-text>
                <v-row no-gutters align="center">
                  <v-col class="title">{{$t('m.billing.method')}}</v-col>
                </v-row>
              </v-card-text>
            </v-sheet>

            <v-row class="mx-2" v-if="!loadingPaymentSource && paymentSource">
              <v-col cols="auto" class="body-1">{{paymentSource.brand}}</v-col>
              <v-col cols="auto" class="body-1">••••{{paymentSource.last4}}</v-col>
            </v-row>
            <v-row class="mx-2" v-else-if="loadingPaymentSource">
              <v-col cols="auto" class="body-1">
                <v-progress-circular :size="25" color="primary" indeterminate />
              </v-col>
            </v-row>
            <v-row class="mx-2" v-else-if="isFaith"><v-col>請求書</v-col></v-row>
            <v-row class="mx-2" v-else>
              <v-col cols="auto" class="body-1">{{$t('m.billing.noMethod')}}</v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data () {
    return {
      loadingPaymentSource: false,
      stripePlanFree: '',
      stripePlan5K: '',
      stripePlan30K: ''
    }
  },
  computed: {
    ...mapState('auth', ['plans']),
    isFaith () {
      return !!(this.plans && this.plans.recruitApp && this.plans.recruitApp.plan && (!this.plans.recruitApp.paymentSource))
    },
    isInvoice () {
      return ['invoice-with-site', 'invoice-without-site'].includes(this.plans.payment)
    },
    paymentSource () {
      return this.plans && this.plans.recruitApp ? this.plans.recruitApp.paymentSource : null
    },
    planId () {
      return this.plans && this.plans.recruitApp ? this.plans.recruitApp.plan : null
    }
  },
  methods: {
    ...mapActions('auth', ['updateUserPaymentSource'])
  },
  async mounted () {
    if (this.isInvoice) {
      return
    }

    if (this.isFaith) {
      // skip
    } else {
      if (!this.paymentSource) {
        this.loadingPaymentSource = true
        try {
          await this.updateUserPaymentSource()
        } catch (err) {
          console.error("error updating user's payment source", err) // eslint-disable-line no-console
        } finally {
          this.loadingPaymentSource = false
        }
      }
    }

    this.stripePlanFree = process.env.VUE_APP_STRIPE_PLAN_FREE
    this.stripePlan5K = process.env.VUE_APP_STRIPE_PLAN_5K
    this.stripePlan30K = process.env.VUE_APP_STRIPE_PLAN_30K
  }
}
</script>

<style>
</style>
