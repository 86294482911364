<template>
  <Billing />
</template>

<script>
import Billing from '@/components/billing'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    Billing
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_BILLING)
  }
}
</script>
